@use '../dimensions.scss' as *;

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop {
    }

    &__image {
        width: 100%;
        border: 1px solid #111;
        padding: 4px;
        background-color: #444;
        @include tablet {
            padding: 0;
        }
    }

    &__image-div {
        margin-top: 1rem;
        @include tablet {
            margin-top: 2rem;
            max-width: 50%;
        }
    }

    &__disaster {
        background-color: #F0F7FF;
        padding: 1rem;
        border-radius: 2px;
        border: 1px solid #111;
        @include tablet {
            padding: 2rem;
        }
        @include desktop {
            min-width: 300px;
            max-width: 900px;
        }
    }

    &__heading {
        margin-bottom: 1rem;
    }


    &__image-div {
        position: relative;
    }

    // &__image--good {
    //     border: 2px solid #00C853;

        
    // }

    // &__image--bad {
    //     border: 2px solid #d6251b;

    // }

   

    &__status {
        position: absolute;
        padding: 0.5rem;
        background-color: #F0F7FF;
        z-index: 2;
        top: -0.5rem;
        right: -0.5rem;
        font-weight: bold;
        border: 1px solid #111;
        @include tablet {
            padding: 1rem;
            font-size: 1.1rem;
        }
    }

    &__description {
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
        @include tablet {
            font-size: 1.2rem;

        }
    }

    &__images {
        @include tablet {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
        }
    }
}