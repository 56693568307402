@use '../dimensions.scss' as *;

.form {
    border: 1px solid #111;
    background-color: #F0F7FF;
    padding: 1rem;
    @include tablet {
        padding: 2rem;
    }
    @include desktop {
        max-width: none;
        max-width: 900px;
        margin: 0 auto;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__div {
        height: 2.5rem;
        margin-bottom: 2.5rem;
        position: relative;
    }

    &__div:last-of-type {
        margin-bottom: 2rem;
    }

    &__input {
        width: 100%;
        height: 100%;
        border: none;
        border: 1px solid #333;
        font-size: 1rem;
        outline: none;
        padding-left: 0.5rem;
        background-color: transparent;
    }

    &__input:focus ~ &__label,
    &__input:valid ~ &__label {
        transform: translateY(-1.4rem);
        font-size: 0.8rem;
        color: #111;
    }

    &__input:focus,
    &__input:valid {
        border: 1px solid #111;
    }


    &__label {
        position: absolute;
        bottom: 0.5rem;
        left: 0.5rem;
        padding: 0.2rem;
        background-color: #F0F7FF;
        color: #333;
        transition: 0.2s;
        cursor: text;
    }
 
    &__submit {
        height: 3rem;
        width: 100%;
        cursor: pointer;
        font-size: 1rem;
        color: white;
        background-color: #d6251b;
        transition: 0.2s;
        font-weight: bold;
        border: none;
        @include desktop {
            max-width: 16rem;
        }
    }

    &__submit:hover {
        background-color: #ff251b;
        color: #F0F7FF;
    }

    &__heading {
        margin-bottom: 2rem;
    }

    &__fields {
        @include desktop {
            display: flex;
            gap: 2rem;
            justify-content: space-between;
        }
    }

    &__div {
        @include desktop{
            flex-grow: 2;
        }
    }

    
}