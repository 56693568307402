@use './dimensions.scss' as *;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::selection {
  color: white;
  background-color: #d6251b;
}

body {
  background-color: #222;
}


html {
  background-color: #111;
  scroll-behavior: smooth;
}