@use '../dimensions.scss' as *;

.cta {
    padding: 1rem;
    background-color: #F0F7FF;
    border: 1px solid #111;
    @include tablet {
        padding: 2rem;
    }
    @include desktop {
        max-width: 900px;
        margin: 0 auto;

    }

    &__list {
        list-style: square;
        padding-left: 1rem;
        @include desktop {
            max-width: 70%;
            margin-bottom: 2rem;
        }
    }

    &__heading {
        margin-bottom: 1rem;
    }

    &__div {
        margin-bottom: 2rem;
        padding-right: 1rem;
        @include desktop {
            margin-bottom: 0;
        }
    }

    &__div:last-of-type {
        margin-bottom: 0;
        padding-right: 0;
    }

    &__item {
        margin-bottom: 1rem;
        font-size: 1.1rem;
        @include desktop {
            font-size: 1.2rem;
        }
    }

    &__item:last-of-type {
        @include desktop {
            margin-bottom: 0;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        text-decoration: none;
        background-color: #d6251b;
        color: white;
        font-weight: bold;
        transition: 0.2s;
        @include desktop {
            width: 16rem;
        }
        }

        &__bold {
            font-weight: bold;
        }

        &__button:hover {
            background-color: #ff251b;
            color: #F0F7FF;
        }

        &__div--cta {
            @include desktop {
                padding-left: 1rem;
            }
        }
        
    }

    

   
